import React from "react";

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="absolute bottom-0 bg-gray-900 text-white w-svw p-3">
            <p>&copy; {currentYear} Withstand Security, LLC. All rights reserved.</p>
        </footer>
    );
}

export default Footer;