import React from "react";


function WelcomePage() {

    return (
        <div>
            <h1>Welcome</h1>
            <p>Thank you for signing up!</p>

            <p>Now that you have signed up you will have to verifiy your account before you will be allowed to login.</p>
        </div>
    )
}


export default WelcomePage;