import React from "react";
import CompanyCreateForm from "../../components/CompanyCreateForm.tsx";

function CreateCompanyPage() {
    return (
        <div>
            <CompanyCreateForm />
        </div>
    );
}

export default CreateCompanyPage;