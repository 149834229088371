import React from "react";

function CompanyCreateForm(props) {

    function CreateCompany() {
        alert('Create Company Clicked');
    }

    return(

        <div className="form form-field">
            <div className="form-field">
                <h1 className="form-field">Create Company</h1>
            </div>
            <div className="form-field">
                <label htmlFor="company-name" className="form-field">Company Name</label>
                <input type="text" name="company-name" id="company-name" className="form-field" />
            </div>
            <div className="form-field">
                <button className="form-field" onClick={CreateCompany}>Create</button>
            </div>
        </div>
    )
}

export default CompanyCreateForm;