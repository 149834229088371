import React from "react";

function Header() {
  return (
    <header className="bg-gray-900 text-white w-svw p-4 text-2xl">
      <h1 className="">Withstand Security</h1>
    </header>
  );
}

export default Header;