import React from 'react'
import LogingBox from '../../components/LoginBox.tsx'


function LoginPage() {
  return (
    <div className="grid grid-cols-3 ">
      <div className="w-auto"></div>
      <div className="w-auto">
        <LogingBox />
      </div>
      <div className="w-auto"></div>
    </div>
  )
}


export default LoginPage