import { Account, Client, Databases, ID } from "appwrite";

const ConfigData = {
    "api_endpoint": "https://api.withstandtheattack.com/v1",
    "project_id": "6618048525e3c751a3c3"
}

function Accounts() {
    const client = new Client().setEndpoint(ConfigData.api_endpoint).setProject(ConfigData.project_id);
    const account = new Account(client);

    return account;
}

const collections = [
    {
        'databaseId':'66184ca363af99eea11e',
        'collectionId':'66184cad51ec64ca99f1',
        'collectionName':'companies'
    },
    {
        'databaseId':'66184ca363af99eea11e',
        'collectionId':'66184cf1cc0bc0f9b9e3',
        'collectionName':'contacts'
    }
]

const db = {};
try {
    const client = new Client().setEndpoint(ConfigData.api_endpoint).setProject(ConfigData.project_id);
    collections.forEach((col) => {
        db[col.collectionName] = {
            create: (obj, id = ID.unique(), permissions = ['*'] ) => new Databases(client).createDocument(col.databaseId, col.collectionId, id, obj, permissions),
            update: (id, obj) => new Databases(client).updateDocument(col.databaseId, col.collectionId, id, obj),
            get: (id) => new Databases(client).getDocument(col.databaseId, col.collectionId, id),
            delete: (id) => new Databases(client).deleteDocument(col.databaseId, col.collectionId, id),
            list: (queries) => new Databases(client).listDocuments(col.databaseId, col.collectionId, queries),
        }
    });
} catch (e) {
    console.log(e);
}

export { Accounts, db };