import React from "react";
import { Link } from "react-router-dom";
import { Accounts } from "../database/database";

function Navbar() {

    const [anonymous, setAnonymous] = React.useState(true);
    const [admin, setAdmin] = React.useState(false);
    const [loggedin, setLoggedin] = React.useState(false);
    
    async function MenuControl() {
        try {

            const account = await Accounts().getSession('current');
            const user = await Accounts().get(account.userId);
            
            setAnonymous(false);
            setLoggedin(true);
            setAdmin(false);
            console.log('User logged in.');
            console.log('user:', user);
            console.log('admin:', admin);
            console.log('loggedin:', loggedin);
            return account;
        } catch (e) {
            console.log('User not logged in.')
            setAnonymous(true);
            return null;

        }
    }

    MenuControl();

    async function Logout() {
        try {
            await Accounts().deleteSession('current');
            window.location.href = '/';
        } catch (e) {
            console.log('User not logged in.')
        }
    }

       // Check to see if the user cookie is set
    return (
        <div className="shadow-sm mb-4">
            <ul className="flex bg-slate-300">
                <li className="nav-link"><Link to="/">Home</Link></li>
                { anonymous ? '' : <li className="nav-link"><Link to="/dashboard">Dashboard</Link></li> }
                { loggedin ? '' : <li className="nav-link anonymous"><Link to="/user/login">Login</Link></li> }
                { loggedin ? '' : <li className="nav-link anonymous"><Link to="/user/signup">Sign Up</Link></li> }
                { admin ? <li className="nav-link admin"><Link to="/company/create">Company</Link></li> : '' }
                { loggedin ? <li className="nav-link loggedin"><button onClick={Logout}>Logout</button></li> : ''}
            </ul>
        </div>
    )
}


export default Navbar;