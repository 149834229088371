import React from "react";
import { useState } from "react";
import { Accounts } from '../database/database.jsx';
import { ID } from "appwrite";
import { NavLink } from 'react-router-dom';

function SignupForm(props) {

    const [email, setEmail] = useState(props?.email ?? "");
    const [password, setPassword] = useState(props?.password ?? "");
    const [passwordConfirm, setPasswordConfirm] = useState(props?.passwordConfirm ?? '');
    const [username, setUsername] = useState(props?.username ?? '');
    const [error] = useState(props?.error ?? '');

    function Signup() {

        if (password !== passwordConfirm) {
            this.error = 'Passwords do not match';
            return;
        }

        const promise = Accounts().create(ID.unique(), email, password, username);
            
        promise.then(function (response) {
            window.location.href = '/user/welcome'; // Success
        }, function (error) {
            this.setError(error.message); // Error
        });
    }

    return (
        <div id="signup-form" className="form form-field">
            <h1 className="form-field">Signup Form</h1>
            <div className="form-field">
                <label htmlFor="email" className="form-field">Email:</label>
                <input type="text" id="email" name="email" className="form-field" value={email} onInput={e => setEmail((e.target as HTMLInputElement).value)} />
            </div>
            <div className="form-field">
                <label htmlFor="password" className="form-field">Password:</label>
                <input type="password" id="password" name="password" className="form-field" value={password} onInput={e => setPassword((e.target as HTMLInputElement).value)} />
            </div>
            <div className="form-field">
                <label htmlFor="passwordConfirm" className="form-field">Confirm Password:</label>
                <input type="password" id="passwordConfirm" name="password" className="form-field" value={passwordConfirm} onInput={e => setPasswordConfirm((e.target as HTMLInputElement).value)} />
            </div>
            <div className="form-field">
                <label htmlFor="username" className="form-field">Username:</label>
                <input type="text" id="username" name="username" className="form-field" value={username} onInput={e => setUsername((e.target as HTMLInputElement).value)} />
            </div>
            <div className="form-field">
                <p className="text-red-500">{error}</p>
            </div>
            <div className="form-field">
                <button  className="form-field" onClick={Signup}>Sign up</button>
            </div>
            <div className="form-field">
                <div>
                    <NavLink a href="/user/login" className="form-field">Login</NavLink>
                </div>
            </div>
        </div>
    );
}

export default SignupForm;