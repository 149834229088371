import './App.css';
import { Routes, Route } from 'react-router-dom';

// Components
import Footer from './containers/Footer.tsx';
import Header from './containers/Header.tsx';
import Navbar from './containers/Navbar';

// Pages
import CreateCompanyPage from './pages/company/CreateCompanyPage.tsx';

import LoginPage from './pages/user/LoginPage.tsx';
import SignupPage from './pages/user/SignupPage.tsx';
import ForgotPasswordPage from './pages/user/ForgotPasswordPage.tsx';
import WelcomePage from './pages/user/WelcomePage.tsx';
import IntroductionPage from './pages/introduction/introductionpage.tsx';

function App() {
  return (
    <div id="panel" className='bg-gray-500 h-svh'>
      <Header></Header>
      <Navbar />
        <Routes>
          <Route path="/" element={<WelcomePage />}></Route>
          <Route path="/introduction" element={<IntroductionPage />}></Route>

          <Route path="/dashboard" element={<WelcomePage />}></Route>

          <Route path="/company/create" element={<CreateCompanyPage/>}></Route>

          <Route path="/user/login" element={<LoginPage/>}></Route>
          <Route path="/user/signup" element={<SignupPage />}></Route>
          <Route path="/user/forgot-password" element={<ForgotPasswordPage />}></Route>
          <Route path="/user/welcome" element={<WelcomePage />}></Route>
        </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
