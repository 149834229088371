import React from "react";
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

function ForgotPasswordForm(props) {

    const [email, setEmail] = useState(props?.email ?? '');

    function SendResetLink() {
        // Send reset link
        
    }

    return (
        <div id="forgot-password-box" className="form form-field">
            <h1 className="form-field">Forgot Password</h1>
            <div className="form-field">
                <label htmlFor="email" className="form-field">Email:</label>
                <input type="text" id="email" name="email" className="form-field" value={email} onInput={e => setEmail((e.target as HTMLInputElement).value)} />
            </div>
            <div className="form-field">
                <button className="form-field" onClick={SendResetLink}>Send reset link</button>
            </div>
            <div className="p-3 flex">
                <div className="flex-auto">
                    <NavLink a href="/user/login" className="form-field">Log In</NavLink>
                </div>
                <div>
                    <NavLink a href="/user/signup" className="form-field">Sign Up</NavLink>
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordForm;