import React from "react";
import SignupForm from "../../components/SignupForm.tsx";

function SignupPage(props) {
  return (
    <div className="grid grid-cols-3 h-auto">
      <div className="w-auto"></div>
      <div className="w-auto">
        <SignupForm />
      </div>
      <div className="w-auto"></div>
    </div>
  );
}


export default SignupPage;