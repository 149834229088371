import React from "react";
import { useState } from 'react';
import { Accounts } from "../database/database.jsx";
import { NavLink } from 'react-router-dom'


function LogingBox(props: any) {

  const [username, setUsername] = useState(props?.username ?? '');
  const [password, setPassword] = useState(props?.password ?? '');
  const [error] = useState(props?.error ?? '');

  function Login() {

    const promise = Accounts().createEmailPasswordSession(username, password);

    promise.then(function (response) {
      window.location.href = '/dashboard';
    }, function (error) {
      this.error = error.message;
    });
  }
  

  return (
    <div id="login-box" className="form form-field">
      <h1 className="form-field">Login Box</h1>
      <div className="form-field">
        <label htmlFor="username" className="form-field">Username:</label>
        <input type="text" id="username" name="username" className="form-field" value={username} onInput={e => setUsername((e.target as HTMLInputElement).value)} />
      </div>
      <div className="form-field">
        <label htmlFor="password" className="form-field">Password:</label>
        <input type="password" id="password" name="password" className="form-field" value={password} onInput={e => setPassword((e.target as HTMLInputElement).value)} />
      </div>
      <div className="form-field">
        <button className="form-field" onClick={Login}>Log in</button>
      </div>
      <div className="form-field">
        <p className="text-red-500">{error}</p>
      </div>
      <div className="p-3 flex">
        <div className="flex-auto">
          <NavLink a href="/user/forgot-password" className="form-field">Forgot Password?</NavLink>
        </div>
        <div>
          <NavLink a href="/user/signup" className="form-field">Sign Up</NavLink>
        </div>
      </div>
    </div>
  );
}

export default LogingBox;