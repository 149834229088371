import React from "react";
import ForgotPasswordForm from "../../components/ForgotPasswordForm.tsx";


function ForgotPasswordPage() {
    return (
        <div className="grid grid-cols-3 h-auto">
            <div className="w-auto"></div>
            <div className="w-auto">
                <ForgotPasswordForm />
            </div>
            <div className="w-auto"></div>
        </div>
    )
}


export default ForgotPasswordPage